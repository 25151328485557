import { Box, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react'
import StandardField from '../Components/StandardField';
//import CustomRadioGroup from '../Components/CustomRadioGroup';
import CustomCheckBox from '../Components/CustomCheckBox';
import Calendar from '../Components/Calendar';
import dayjs from 'dayjs';
import { formReducer, getDuration } from '../utils/globalFunctions';
import TimeSlot from '../Components/TimeSlot';
import CustomBtn from '../Components/CustomBtn';
import { useNavigate, useParams } from 'react-router-dom';
import { bookingDetails } from '../Endpoints/UserEndpoints';
import { useSelector } from 'react-redux';
import { url } from '../utils/Host';
import Loader from '../Components/Loader';
import SnackAlert from '../Components/SnackAlert';

const ServiceDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { palette: { primary } } = useTheme();
  const isSmall = useMediaQuery('(max-width:430px)');
  const isMedium = useMediaQuery('(max-width:680px)');
  const isLarge = useMediaQuery('(max-width:1059px)');
  const user = useSelector(state => state.auth.userData);

  const storedDetails = JSON.parse(localStorage.getItem('bookingDetails'));
  const checkoutDetails = (storedDetails && storedDetails?.bookingDetails?._id === params.id) ? storedDetails : null;

  const [type, setType] = useState();
  const [slots, setSlots] = useState([]);
  const [message, setMessage] = useState('');
  const [details, setDetails] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openMaxHourWarn, setOpenMaxHourWarn] = React.useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [customerLimit, setCustomerLimit] = useState(null);
  const [formData, setFormData] = useReducer(formReducer, {});

  const mapStyle = `feature:water|element:geometry|color:0x75cff1`;
  const markerPosition = details?.facilitiesDetails[0]?.MarkerPosition;
  const mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${markerPosition?.lat},${markerPosition?.lng}&zoom=12&scale=2&size=600x450&markers=size:large|color:0x1782CA|${markerPosition?.lat},${markerPosition?.lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&style=${mapStyle}`;

  const getDetails = async () => {
    setIsLoaded(false);
    try {
      const response = await bookingDetails(params.id, user?._id ?? "");
      if (response?.details) {
        const details = response.details;
        setDetails(details ?? null);
        setFormData({
          target: {
            name: 'Price',
            value: details?.Price
          }
        })

        setSlots(details?.availableSlots || []);
        setCustomerLimit(details?.TotalCustomer || null);
      } else {
        setSlots([]);
        setDetails({});
      }
      setIsLoaded(true);
    }
    catch (e) {
      console.log(e);
    }
  };

  const handleProceedForCheckout = () => {
    if (!termsAccepted && details?.facilitiesDetails[0].TermsEnabled) {
      setOpenTerms(true);
      return;
    }
    if (!formData['slots'] || !Object.keys(formData['slots'])?.length) {
      setType('error');
      setMessage('Please select atleast one available time slot!');
      setShowAlert(true);
      return;
    }

    if (details?.facilitiesDetails[0]?.MaxHours && details?.facilitiesDetails[0]?.MaxHours !== 0 && getDuration(formData['slots'], true) > details?.facilitiesDetails[0]?.MaxHours) {
      setOpenMaxHourWarn(true);
      return;
    }

    const data = {
      bookingDetails: {
        _id: details?._id,
        type: details?.type,
        Name: details?.Name,
        StartDate: details?.StartDate,
        EndDate: details?.EndDate,
        Price: details?.Price,
        facilitiesDetails: details?.facilitiesDetails,
        Facility: details?.Facility,
        ClassType: details?.ClassType ?? ""
      },
      ...formData
    }
    localStorage.setItem('bookingDetails', JSON.stringify(data))
    if (!user) {
      navigate('/login');
      return;
    }
    navigate('/checkout', { state: data });
  }

  useLayoutEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    let items = [
      {
        target: {
          name: 'noOfPeople',
          value: checkoutDetails?.noOfPeople ?? 1
        }
      },
      {
        target: {
          name: 'selectedDate',
          value: dayjs().format('MM/DD/YYYY')
        }
      },
      {
        target: {
          name: 'type',
          value: dayjs().format('A')
        }
      }
    ];
    items.map(x => setFormData(x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAvailableSlots(slots?.filter(x => dayjs(x.date).format('MM/DD/YYYY') === formData['selectedDate'])[0]?.time
      ?.filter(x => {
        const startDate = dayjs(x.start, 'HH:mm');
        if (formData['selectedDate'] === dayjs().format('MM/DD/YYYY')) {
          if (startDate.diff(dayjs()) >= 1) {
            return x;
          }
          return null;
        } 
        return x;
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData['selectedDate'], slots]) //, formData['type']
  // console.log(details?.serviceCourts);
  return (
    <>
      {isLoaded ?
        <>
          {/* Banner */}
          <Box
            sx={{
              background: `linear-gradient(0deg, rgb(23 130 202 / 0%) 0%, rgb(23 130 202 / 0%) 100%), url(${url}/picAdmin/${details?.Images?.[0]}), lightgray 50% / cover no-repeat`,
              width: '100%',
              // height: { md: '600px', xs: isSmall ? '300px' : '400px' },
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Box
              sx={{
                py: isSmall ? '5px' : isLarge ? '10px' : '15px',
                mt: 'auto',
                width: '100%',
                borderRadius: '0px 0px 5px 5px',
                background: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <Typography
                sx={{
                  color: primary.main,
                  fontFamily: 'Nunito Sans',
                  fontSize: { md: '40px', sm: '35px', xs: '22px' },
                  fontWeight: 700,
                  textShadow: '-2px 2px black',
                  textAlign: 'center',
                }}
              >
                {details?.Name}
              </Typography>
              {details?.facilitiesDetails?.length > 0 &&
                <Typography
                  sx={{
                    color: primary.main,
                    fontFamily: 'Nunito Sans',
                    fontSize: { md: '25px', sm: '20px', xs: '16px' },
                    fontWeight: 700,
                    textAlign: 'center',
                    textShadow: '-2px 2px black',
                    mt: isSmall ? '5px' : '10px'
                  }}
                >
                  {details?.facilitiesDetails?.[0]?.Name}
                </Typography>
              }
            </Box>
          </Box>
          {/* Banner */}
          {/* BODY */}
          <Box
            sx={{
              m: isSmall ? '15px' : isMedium ? '30px 25px' : isLarge ? '80px' : '100px 116px'
            }}
          >
            <Typography
              sx={{
                color: '#444',
                fontFamily: 'Nunito Sans',
                fontSize: isSmall ? 20 : isLarge ? '25px' : '32px',
                fontWeight: 700,
                mb: '10px'
              }}
            >
              Booking information
            </Typography>
            <Grid container spacing={isMedium ? '20px' : '40px'}>
              <Grid item md={6} xs={12}>
                {/* <Warning
                  icon={<WarningAmberRoundedIcon
                    sx={{
                      fontSize: isSmall ? '40px' : isLarge ? '50px' : '70px',
                      color: '#FF9F2F'
                    }}
                  />}
                  containerStyle={{
                    p: { sm: '10px', xs: '10px 5px' },
                    '& p': {
                      fontSize: { lg: 14, xs: 12 },
                    },
                    gap: '10px'
                  }}
                  color='255, 159, 47'
                  message='Payments can be made via cash, credit card or bank draft at the JFF Office, 20 St. Lucia Crescent, Kingston 5. Wire transfers are also accepted. Bookings Check-in time is 11am daily and Check-out time is 12pm daily.'
                /> */}
                <Box component={'form'}>
                  <Grid container>
                    <Grid item xs={isMedium ? 5 : 4}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Service Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={isMedium ? 7 : 6}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                        }}
                      >
                        {details?.Name ?? '---'}
                      </Typography>
                    </Grid>
                    <Grid item xs={isMedium ? 5 : 4} mt={isSmall ? '5px' : isLarge ? 2 : 3}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Coach Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={isMedium ? 7 : 8} mt={isSmall ? '5px' : isLarge ? 1 : 3}>
                      <StandardField
                        disabled={true}
                        value={details?.serviceCourts?.length > 0 ? details?.serviceCourts[0]?.CourtName : '---'}
                        name='courtName'
                        onChange={setFormData}
                      />
                    </Grid>
                    <Grid item xs={isMedium ? 5 : 4} mt={isSmall ? '5px' : isLarge ? 2 : 3}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Court Format:
                      </Typography>
                    </Grid>
                    <Grid item xs={isMedium ? 7 : 8} mt={isSmall ? '5px' : isLarge ? 1 : 3}>
                      <StandardField
                        disabled={true}
                        value={details?.serviceCourts?.length > 0 ? details?.serviceCourts[0]?.Format : '---'}
                        name='courtFormat'
                        onChange={setFormData}
                      />
                    </Grid>
                    <Grid item xs={isMedium ? 5 : 4} mt={isSmall ? '5px' : isLarge ? 1 : 3}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Description:
                      </Typography>
                    </Grid>
                    <Grid item sx={{ whiteSpace: 'pre-wrap' }} xs={isMedium ? 7 : 8} mt={isSmall ? '5px' : isLarge ? 1 : 3}>
                      {details?.Description ?? '---'}
                    </Grid>
                  </Grid>
                </Box>
                {/* <Warning
                  icon={<ErrorOutlineRoundedIcon
                    sx={{
                      fontSize: isSmall ? '40px' : isLarge ? '50px' : '60px',
                      color: '#5788F0',
                      mb: '-7px'
                    }}
                  />}
                  color='87, 136, 240'
                  message='Bookings must be made at least 3 days(s) before the slot takes place.'
                  containerStyle={{
                    p: '5px',
                    '& p': {
                      fontSize: { lg: 14, xs: 12 },
                    },
                    gap: '10px'
                  }}
                /> */}
                <Calendar
                  selectedSlots={formData['slots']}
                  availableSlots={availableSlots}
                  value={dayjs(formData['selectedDate'])}
                  slots={slots}
                  onChange={(val) => {
                    setFormData({
                      target: {
                        name: 'selectedDate',
                        value: val
                      }
                    })
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    flexWrap: 'wrap',
                    mt: 2
                  }}
                >
                  <Typography
                    component={'span'}
                    sx={{
                      color: '#15192C',
                      fontFamily: 'Nunito Sans',
                      fontSize: isSmall ? 14 : 16,
                      fontWeight: 500,
                    }}
                  >
                    Choose Time Slot:
                  </Typography>
                  {/* <CustomRadioGroup
                    name='type'
                    onChange={setFormData}
                    value={formData['type']}
                    options={[{ label: 'AM', value: 'AM' }, { label: 'PM', value: 'PM' }]}
                  /> */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: { lg: '20px', xs: '10px' },
                    flexWrap: 'wrap',
                    mt: 1
                  }}
                >
                  {availableSlots?.length > 0 ?
                    availableSlots?.map((x, i) => {
                      let isSelected = false;
                      if (formData['slots']) {
                        const currentDates = formData['slots'][formData['selectedDate']];
                        isSelected = (currentDates?.find(date => date?.start === x.start && date?.end === x.end) ? true : false);
                      }
                      return (
                        <TimeSlot
                          key={i}
                          item={x}
                          isSelected={isSelected}
                          handleSelect={(val, action) => {
                            let tempPrice = Number(formData['Price']);
                            //const slot = formData['slots']?.[formData['selectedDate']];
                            if (formData['slots'] && (formData['slots'][formData['selectedDate']]?.length > 0 || Object.keys(formData['slots'])?.length > 0)) {
                              if (action === 'remove') {
                                if (tempPrice > details?.Price) {
                                  tempPrice -= details?.Price;
                                }
                              } else {
                                tempPrice += details?.Price;
                              }
                            }
                            setFormData({
                              target: {
                                name: 'Price',
                                value: tempPrice
                              }
                            })
                            // if (slot) {
                            //   const removedSlots = slot?.filter(x => x?.start !== val?.start && x?.end !== val?.end);
                            //   let filteredDates = {};
                            //   Object.keys(formData['slots'])?.forEach(x => {
                            //     if (x !== formData['selectedDate']) {
                            //       filteredDates = { ...filteredDates, [x]: formData['slots'][x] }
                            //     }
                            //     return filteredDates
                            //   })
                            //   setFormData({
                            //     target: {
                            //       name: 'slots',
                            //       value: (action === 'remove' && removedSlots?.length === 0) ? filteredDates : { ...formData['slots'], [formData['selectedDate']]: action === 'remove' ? removedSlots : [...slot, val] }
                            //     }
                            //   })
                            // } else {
                              setFormData({
                                target: {
                                  name: 'slots',
                                  value: formData['slots'] ? { ...formData['slots'], [formData['selectedDate']]: [val] } : { [formData['selectedDate']]: [val] }
                                }
                              })
                            // }
                          }}
                        />
                      )
                    })
                    :
                    <Typography>
                      No Timeslots available, please check in another day.
                    </Typography>
                  }
                </Box>
              </Grid>
              <Grid item md={6} xs={12} textAlign={'center'}>
                <Box
                  sx={{
                    '& img': {
                      maxHeight: { md: '100%', xs: '300px' },
                      objectFit: 'cover'
                    }
                  }}
                >
                  <img
                    src={mapURL}
                    width={'100%'}
                    alt=''
                  />
                </Box>
                <Box component={'form'} mt={isSmall ? '25px' : '45px'} textAlign={'left'}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item sm={3} xs={4}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Address:
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={8}>
                      <Typography
                        sx={{
                          color: '#707070',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                        }}
                      >
                        {details?.Address ?? '---'}
                      </Typography>
                    </Grid>
                    <Grid item sm={3} xs={4}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Surface:
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={8}>
                      <Typography
                        sx={{
                          color: '#707070',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                        }}
                      >
                        {details?.serviceCourts?.length > 0 ?
                          details?.serviceCourts[0]?.Surface
                          :
                          '-'
                        }
                      </Typography>
                    </Grid>
                    <Grid item lg={3} md={3.5} sm={3} xs={4}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        Amenities:
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={8.5} sm={9} xs={8}>
                      {details?.amenities?.length > 0 ?
                        details?.amenities?.map(x => (
                          <Box
                            component={'span'}
                            key={x?.name}
                          >
                            <CustomCheckBox
                              name={'services'}
                              label={x?.name}
                              checked={true}
                              checkBoxStyle={{
                                'span': {
                                  fontSize: { sm: 14, xs: 12 },
                                  p: { sm: '5px', xs: '3px' }
                                },
                                'svg': {
                                  width: { sm: '1em', xs: '20px' },
                                  height: { sm: '1em', xs: '20px' },
                                }
                              }}
                            />
                          </Box>
                        ))
                        :
                        '-'
                      }
                    </Grid>
                    <Grid item sm={3} xs={4}>
                      <Typography
                        sx={{
                          color: '#15192C',
                          fontFamily: 'Nunito Sans',
                          fontSize: isSmall ? 14 : 16,
                          fontWeight: 600,
                        }}
                      >
                        {customerLimit && 'No of people / '}Price :
                      </Typography>
                    </Grid>
                    <Grid item sm={9} xs={8}>
                      <Box
                        sx={{
                          gap: '30px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {customerLimit &&
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <CustomBtn
                              title='-'
                              btnStyle={{
                                padding: 0,
                                minWidth: { sm: '45px', xs: '25px' }
                              }}
                              onClick={() => {
                                if (formData['noOfPeople'] > 1) {
                                  setFormData({
                                    target: {
                                      name: 'noOfPeople',
                                      value: formData['noOfPeople'] - 1
                                    }
                                  })
                                }
                              }}
                            />
                            <StandardField
                              value={formData['noOfPeople']}
                              inputStyle={{
                                '& input': {
                                  textAlign: 'center'
                                },
                                maxWidth: '70px'
                              }}
                            />
                            <CustomBtn
                              title='+'
                              btnStyle={{
                                padding: 0,
                                minWidth: { sm: '45px', xs: '25px' }
                              }}
                              onClick={() => {
                                if (customerLimit > formData['noOfPeople']) {
                                  setFormData({
                                    target: {
                                      name: 'noOfPeople',
                                      value: formData['noOfPeople'] + 1
                                    }
                                  })
                                } else {
                                  setType('error');
                                  setMessage('No of people limit exceeded!')
                                  setShowAlert(true);
                                }
                              }}
                            />
                          </Box>
                        }
                        <Typography
                          sx={{
                            color: '#1782CA',
                            fontFamily: 'Nunito Sans',
                            fontSize: isSmall ? 18 : isLarge ? 20 : '25px',
                            fontWeight: 500
                          }}
                          component={'span'}
                        >
                          QR&nbsp;
                          <Typography
                            sx={{
                              color: '#444',
                              fontFamily: 'Nunito Sans',
                              fontSize: isSmall ? 14 : isLarge ? 16 : '22px',
                              fontWeight: 700
                            }}
                            component={'span'}
                          >
                            {(formData['noOfPeople'] * Number(formData['Price']))?.toFixed(2)}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <CustomBtn
                    title='Proceed for Checkout'
                    onClick={handleProceedForCheckout}
                    btnStyle={{
                      borderRadius: '29px',
                      fontSize: isSmall ? 14 : isLarge ? 16 : 18,
                      textTransform: 'capitalize',
                      width: '100%',
                      mt: '30px'
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

          </Box>
          {/* BODY */}
        </>
        :
        <Loader />
      }
      <SnackAlert
        type={type}
        open={showAlert}
        message={message}
        handleClose={() => setShowAlert(false)}
      />
      <Dialog onClose={() => setOpenTerms(false)} open={openTerms}>
        <DialogTitle>Terms And Condition</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
            {details?.facilitiesDetails[0]?.Terms}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={() => { setOpenTerms(false); }}>Cancel</Button>
          <Button variant='contained' color='primary' onClick={() => { setTermsAccepted(true); setOpenTerms(false); }}>Agree</Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={() => setOpenMaxHourWarn(false)} open={openMaxHourWarn}>
        <DialogTitle>Max Hour Condition Reached <br />تجاوز عدد الساعات المسموح حجزها</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
            You are trying to book more than {details?.facilitiesDetails[0]?.MaxHours} hours, kindly communicate with the facility before continuing.
            <br />
            لقد تجاوزت عدد الساعات المسموح حجزها عن طريق الموقع وهي {details?.facilitiesDetails[0]?.MaxHours} ساعات, يرجى مراجعة ادارة النادي للمساعدة
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={() => { setOpenMaxHourWarn(false); }}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ServiceDetails
